<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="">
    <v-container>
      <h1>
        {{ $t("experience.title.part1") }}
        <span class="highlight"> {{ $t("experience.title.part2") }}</span>
      </h1>
      <p>{{ $t("experience.subtilte") }}</p>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            color="info"
            outlined
            v-model="experience.job"
            :rules="rules"
            :label="$t('experience.position')"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="experience.employer"
            :rules="rules"
            :label="$t('experience.company')"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3" sm="6" xs="6">
          <v-menu
            ref="menuStart"
            v-model="menuStart"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                color="info"
                v-model="computedDateFormatted"
                label="Fecha inicio"
                prepend-icon="far fa-calendar-alt"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="experience.dateStart"
              no-title
              color="info"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="menuStart = false">{{
                $t("cancel")
              }}</v-btn>
              <v-btn
                text
                color="info"
                @click="$refs.menuStart.save(experience.dateStart)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3" sm="6" xs="6">
          <v-menu
            :disabled="experience.checkbox"
            ref="menuEnd"
            v-model="menuEnd"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="experience.checkbox"
                color="info"
                v-model="computedDateFormattedEnd"
                :label="$t('experience.endDate')"
                prepend-icon="far fa-calendar-alt"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="experience.dateEnd"
              no-title
              color="info"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="menuEnd = false">{{
                $t("cancel")
              }}</v-btn>
              <v-btn
                text
                color="info"
                @click="$refs.menuEnd.save(experience.dateEnd)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="experience.city"
            :rules="rules"
            :label="$t('city')"
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-checkbox
            color="info"
            v-model="experience.checkbox"
            :label="$t('experience.positionNow')"
            required
          ></v-checkbox>
        </v-col>
        <v-col cols="12" align="right">
          <v-textarea
            outlined
            v-model="experience.bio"
            color="info"
            :label="$t('description')"
          >
          </v-textarea>
          <v-btn
            v-if="edit"
            color="error"
            class="mr-4"
            @click="cancelButton"
            small
          >
            <v-icon left>far fa-window-close</v-icon>
            {{ $t("cancel") }}
          </v-btn>
          <span></span>
          <v-btn
            :disabled="!valid"
            color="secondary"
            class="mr-4"
            @click="addExperience"
            small
          >
            <v-icon left>{{ edit ? "far fa-edit" : "fas fa-plus" }}</v-icon>
            {{ edit ? $t("update") : $t("position") }}
          </v-btn>
        </v-col>
        <v-col>
          <template>
            <v-data-table
              dense
              :headers="headers"
              :items="cv.listExperience"
              :hide-default-footer="true"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  :disabled="edit"
                  color="error"
                  @click="removeItem(item)"
                  size="100%"
                  style="margin-right: 10px"
                >
                  fas fa-trash-alt
                </v-icon>

                <v-icon
                  :disabled="edit"
                  color="warning"
                  size="100%"
                  @click="editExperience(item)"
                >
                  fas fa-pen
                </v-icon>
              </template>
              <template v-slot:item.date="{ item }">
                {{ item.dateStart ? formatDate(item.dateStart) : "..." }} ~
                {{ item.checkbox ? "Presente" : formatDate(item.dateEnd) }}
              </template>
              <template v-slot:item.bio="{ item }">
                {{
                  item.bio.length > 10
                    ? item.bio.substring(0, 10) + "..."
                    : item.bio
                }}
              </template>
              <template v-slot:item.job="{ item }">
                {{
                  item.job.length > 10
                    ? item.job.substring(0, 10) + "..."
                    : item.job
                }}
              </template>
              <template v-slot:item.employer="{ item }">
                {{
                  item.employer.length > 10
                    ? item.employer.substring(0, 10) + "..."
                    : item.employer
                }}
              </template>
              <template v-slot:item.city="{ item }">
                {{
                  item.city.length > 10
                    ? item.city.substring(0, 10) + "..."
                    : item.city
                }}
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right">
          <v-btn color="white" class="mr-4" @click="goBack">
            <v-icon left dark>fas fa-arrow-left</v-icon>
            {{ $t("back") }}
          </v-btn>

          <v-btn
            :disabled="!validNext"
            color="info"
            class="mr-4"
            @click="validate"
          >
            {{ $t("next") }}
            <v-icon right>fas fa-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5"> {{ $t("confirm") }}</v-card-title>
            <v-card-text>{{ $t("confirmValidation") }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="info darken-1" text @click="dialog = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="error darken-1"
                text
                @click="deleteExperience(itemRemove)"
              >
                {{ $t("accept") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: function () {
    return {
      headers: [
        {
          text: this.$i18n.t("position"),
          align: "start",
          sortable: false,
          value: "job",
          class: "header-class-table",
        },
        {
          text:this.$i18n.t("experience.company") ,
          sortable: false,
          value: "employer",
          class: "header-class-table",
        },
        {
          text: this.$i18n.t("startEndDate"),
          sortable: false,
          value: "date",
          class: "header-class-table",
        },
        {
          text: this.$i18n.t("city"),
          sortable: false,
          value: "city",
          class: "header-class-table",
        },
        {
          text: this.$i18n.t("description"),
          sortable: false,
          value: "bio",
          class: "header-class-table",
        },
        {
          text: this.$i18n.t("actions"),
          sortable: false,
          value: "actions",
          class: "header-class-table",
        },
      ],
      editOj: "",
      menuStart: false,
      menuEnd: false,
      dialog: false,
      valid: true,
      itemRemove: "",

      validNext: true,
      edit: false,
      experience: {
        checkbox: false,
        dateStart: new Date().toISOString().substr(0, 10),
        dateEnd: new Date().toISOString().substr(0, 10),
        job: "",
        employer: "",
        city: "",
        bio: "",
      },
      rules: [
        (v) => !!v || this.$i18n.t("rules.mandatory"),
        (v) => (v && v.length > 2) || this.$i18n.t("rules.moreThan"),
      ],
    };
  },
  methods: {
    ...mapActions([
      "setBackStep",
      "setNextStep",
      "setExperience",
      "removeExperience",
    ]),
    isValid() {
      this.valid =
        this.experience.job !== "" &&
        this.experience.employer !== "" &&
        this.experience.city !== ""
          ? true
          : false;
    },
    removeItem(item) {
      this.dialog = true;
      this.itemRemove = item;
    },

    deleteExperience(item) {
      this.removeExperience(item);
      this.dialog = false;
    },
    editExperience(item) {
      this.edit = true;
      this.editOj = item;
      this.experience = JSON.parse(JSON.stringify(item));
    },
    cancelButton() {
      this.experience = {
        checkbox: false,
        dateStart: new Date().toISOString().substr(0, 10),
        dateEnd: new Date().toISOString().substr(0, 10),
        job: "",
        employer: "",
        city: "",
        bio: "",
      };
      this.$refs.form.resetValidation();
      this.valid = false;
      this.edit = false;
    },
    addExperience() {
      this.isValid();
      if (this.valid) {
        if (this.edit) {
          this.deleteExperience(this.editOj);
        }
        this.validNext = true;
        this.setExperience(this.experience);
        this.experience = {
          checkbox: false,
          dateStart: new Date().toISOString().substr(0, 10),
          dateEnd: new Date().toISOString().substr(0, 10),
          job: "",
          employer: "",
          city: "",
          bio: "",
        };
        this.$refs.form.resetValidation();
        this.valid = false;
        this.edit = false;
      } else {
        this.$refs.form.validate();
      }
    },
    goBack() {
      this.setBackStep();
    },
    validate() {
      this.$refs.form.resetValidation();
      this.validNext = true;
      this.setNextStep();
    },
  },
  computed: {
    ...mapState(["cv"]),
    computedDateFormatted() {
      return this.formatDate(this.experience.dateStart);
    },
    computedDateFormattedEnd() {
      return this.formatDate(this.experience.dateEnd);
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 3em;
}
p {
  font-size: 1.1em;
  margin-bottom: 3em;
}
</style>
