<template>
  <v-card color="grey lighten-4" cols="12" flat tile>
    <v-toolbar height="50%">
      <v-col md="3" lg="3" xl="3" sm="3" xs="3">
        <v-select
          v-model="cv.typeOfLetter"
          :items="sortArrayAlf(typesLetters)"
          menu-props="auto"
          hide-details
          label="Select type of Letter"
          color="info"
          @input="changeTemplate"
          dense
        ></v-select>
      </v-col>
      <v-col md="3" lg="3" xl="3" sm="3" xs="3">
        <v-select
          :items="sortArrayAlf(sizes)"
          v-model="cv.sizeOfLetter"
          menu-props="auto"
          item-text="text"
          item-value="value"
          hide-details
          label="Select size"
          color="info"
          @input="changeTemplate"
          dense
        ></v-select>
      </v-col>
      <v-col md="3" lg="3" xl="3" sm="3" xs="3">
        <v-select
          v-model="cv.templateType"
          :items="sortArrayAlf(types)"
          hide-details
          menu-props="auto"
          label="Select template"
          color="info"
          @input="changeTemplate"
          dense
        ></v-select>
      </v-col>
      <v-col md="3" lg="3" xl="3" sm="3" xs="3">
        <v-select
          v-model="cv.align"
          :items="sortArrayAlf(aligns)"
          hide-details
          menu-props="auto"
          label="Select align"
          color="info"
          @input="changeTemplate"
          dense
        ></v-select>
      </v-col>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import utilities from "../services/utilities";

export default {
  data: () => ({
    data: null,
    types: [
      "Simple",
      "Primary",
      "Elegant",
      "Dark",
      "Modern",
      "Concept",
      "Combined",
      "Sublime",
      "Ambience",
      "Cascade",
    ],
    typesLetters: [
      "Arial",
      "Times New Roman",
      "Helvetica",
      "Times",
      "Courier New",
      "Georgia",
      "Platino",
      "Lato",
      "Roboto",
    ],
    sizes: ["Small", "Medium", "Large"],
    aligns: ["Center", "Right", "Left", "Justify"],
  }),
  computed: {
    ...mapState(["cv", "isTwoPagesCv"]),
  },
  methods: {
    ...mapActions(["updateCv", "updateCvInStorage", "updatePagesCv"]),
    changeTemplate() {
      utilities.setFontSizes(this, this.cv.sizeOfLetter);
      this.updateCv(this.cv);
      window.setTimeout(this.updatePagesCv, 100, this.isTwoPagesCv);
      this.updateCvInStorage();
    },
    sortArrayAlf(array) {
      return array.slice().sort();
    },
  },
};
</script>

<style></style>
