<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="">
    <v-container>
      <h1>
        {{ $t("bio.title.part1") }}
        <span class="highlight">{{ $t("bio.title.part2") }}</span>
      </h1>
      <p>{{ $t("bio.subtitle") }}</p>
      <v-row>
        <v-col cols="12" align="right">
          <v-textarea
            outlined
            v-model="cv.bio"
            :rules="rules"
            color="info"
            :label="$t('bio.summary')"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right">
          <v-btn color="white" class="mr-4" @click="goBack">
            <v-icon left dark>fas fa-arrow-left</v-icon>
            {{ $t("back") }}
          </v-btn>

          <v-btn color="info" class="mr-4" @click="validate">
            {{ $t("next") }}
            <v-icon right>fas fa-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: function () {
    return {
      valid: false,
      validNext: true,
      rules: [(v) => !!v || this.$i18n.t('rules.mandatory')],
    };
  },
  methods: {
    ...mapActions(["setBackStep", "setNextStep", "setBio"]),

    goBack() {
      this.setBackStep();
    },
    validate() {
      if (this.cv.bio !== "") {
        this.valid = true;
        this.validNext = true;
      } else {
        this.valid = false;
        this.validNext = false;
      }
      this.$refs.form.validate();
      if (this.valid && this.validNext) {
        this.setBio(this.cv.bio);
        this.setNextStep();
      }
    },
  },
  computed: {
    ...mapState(["cv"]),
  },
};
</script>

<style scoped>
h1 {
  font-size: 3em;
}
p {
  font-size: 1.1em;
  margin-bottom: 3em;
}
</style>
