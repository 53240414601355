<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="">
    <v-container>
      <h1 class="font-weight-bold">
        {{ $t("contact.title.parte1") }}
        <span class="highlight"> {{ $t("contact.title.parte2") }} </span>
      </h1>
      <p>
        {{ $t("contact.littleDesctiption") }}
      </p>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.lastName"
            :rules="nameRules"
            :label="$t('contact.lastname')"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.firstName"
            :rules="nameRules"
            :label="$t('contact.firstname')"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.address"
            :label="$t('contact.address')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.city"
            :label="$t('city')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.postal"
            :label="$t('contact.postalcode')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.phone"
            :label="$t('contact.phone')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.email"
            :rules="emailRules"
            :label="$t('contact.email')"
            required
          ></v-text-field>
        </v-col>
        <v-col align="right">
          <v-btn :disabled="!valid" color="info" class="mr-4" @click="validate">
            {{ $t("next") }}
            <v-icon right dark>fas fa-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: function () {
    return {
      valid: true,
      validNext: true,
      contactMandatory: "",
      nameRules: [
        (v) => !!v || this.$i18n.t("rules.mandatory"),
        (v) =>
          (v && v.length > 3) ||  this.$i18n.t("rules.moreThan"),
      ],
      emailRules: [
        (v) => !!v || this.$i18n.t("rules.mandatory"),
        (v) => /.+@.+\..+/.test(v) || this.$i18n.t("rules.email.valid"),
      ],
    };
  },

  methods: {
    ...mapActions(["setNextStep", "setContact"]),
    validate() {
      if (
        this.cv.contact.firstName !== "" &&
        this.cv.contact.lastName !== "" &&
        this.cv.contact.email !== ""
      ) {
        this.validNext = true;
        this.valid = true;
      } else {
        this.validNext = false;
        this.valid = false;
      }
      this.$refs.form.validate();
      if (this.valid && this.validNext) {
        this.setContact(this.cv.contact);
        this.$refs.form.resetValidation();
        this.setNextStep();
      }
    },
  },

  computed: {
    ...mapState(["cv"]),
  },
};
</script>

<style scoped>
h1 {
  font-size: 3em;
}
p {
  font-size: 1.1em;
  margin-bottom: 3em;
}
</style>
