<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="">
    <v-container>
      <h1 class="font-weight-bold">
        {{ $t("skill.title.part1") }}
        <span class="highlight"> {{ $t("skill.title.part2") }}</span>
      </h1>
      <p>{{ $t("skill.subtitle") }}</p>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            color="secondary"
            outlined
            v-model="skill.name"
            :rules="rules"
            :label="$t('skill.skill')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4" xl="4" sm="12" xs="12">
          <v-rating
            color="secondary"
            background-color="secondary"
            v-model="skill.level"
          ></v-rating>
        </v-col>
        <v-col cols="12" md="12" lg="12" xl="12" sm="12" xs="12" align="right">
          <v-btn
            v-if="edit"
            color="error"
            class="mr-4"
            @click="cancelButton"
            small
          >
            <v-icon left>far fa-window-close</v-icon>
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            :disabled="!valid"
            color="secondary"
            class="mr-4"
            @click="addSkill"
            small
          >
            <v-icon left>{{ edit ? "far fa-edit" : "fas fa-plus" }}</v-icon>
            {{ edit ? $t("update") : $t("skill.skill") }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <template>
            <v-data-table
              dense
              :headers="headers"
              :items="cv.listSkill"
              :hide-default-footer="true"
              class="elevation-1"
            >
              <template v-slot:item.name="{ item }">
                {{
                  item.name.length > 10
                    ? item.name.substring(0, 10) + "..."
                    : item.name
                }}
              </template>
              <template v-slot:item.level="{ item }">
                <div class="progress-concept">
                  <div :class="progressedValue(item.level)"></div>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  :disabled="edit"
                  color="error"
                  @click="removeItem(item)"
                  size="100%"
                  style="margin-right: 10px"
                >
                  fas fa-trash-alt
                </v-icon>

                <v-icon
                  :disabled="edit"
                  color="warning"
                  size="100%"
                  @click="editSkill(item)"
                >
                  fas fa-pen
                </v-icon>
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right">
          <v-btn color="white" class="mr-4" @click="goBack">
            <v-icon left dark>fas fa-arrow-left</v-icon>
            {{ $t("back") }}
          </v-btn>

          <v-btn
            :disabled="!validNext"
            color="info"
            class="mr-4"
            @click="validate"
          >
            {{ $t("next") }}
            <v-icon right>fas fa-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5"> {{ $t("confirm") }}</v-card-title>
            <v-card-text>{{ $t("confirmValidation") }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="info darken-1" text @click="dialog = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="error darken-1"
                text
                @click="deleteSkill(itemRemove)"
              >
                {{ $t("accept") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: function () {
    return {
      editOj: "",
      headers: [
        {
          text: this.$i18n.t("skill.skill"),
          align: "start",
          sortable: false,
          value: "name",
          class: "header-class-table",
          width: "30%",
        },
        {
          text:  this.$i18n.t("skill.level"),
          sortable: false,
          value: "level",
          class: "header-class-table",
          width: "30%",
        },
        {
          text: this.$i18n.t("actions"),
          sortable: false,
          value: "actions",
          class: "header-class-table",
          width: "40%",
          align: "center",
        },
      ],
      valid: false,
      edit: false,
      itemRemove: "",
      dialog: false,
      validNext: true,
      skill: {
        name: "",
        level: 1,
      },
      rules: [(v) => !!v || this.$i18n.t("rules.mandatory")],
    };
  },
  methods: {
    ...mapActions(["setBackStep", "setNextStep", "setSkill", "removeSkill"]),
    validate() {
      this.setNextStep();
    },
    progressedValue(item) {
      return `progress-value-${item}-concept`;
    },
    goBack() {
      this.setBackStep();
    },
    isValid() {
      if (this.skill.name !== "") {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    deleteSkill(item) {
      this.dialog = false;
      this.removeSkill(item);
    },
    removeItem(item) {
      this.dialog = true;
      this.itemRemove = item;
    },
    editSkill(item) {
      this.edit = true;
      this.editOj = item;
      this.skill = JSON.parse(JSON.stringify(item));
    },
    cancelButton() {
      this.skill = {
        name: "",
        level: 0,
      };
      this.$refs.form.resetValidation();
      this.valid = false;
      this.edit = false;
    },
    addSkill() {
      this.isValid();
      if (this.valid) {
        if (this.edit) {
          this.deleteSkill(this.editOj);
        }
        this.validNext = true;
        this.edit = false;
        this.setSkill(this.skill);
        this.$refs.form.resetValidation();
        this.skill = {
          name: "",
          level: 1,
        };
      } else {
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapState(["cv"]),
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 3em;
}
p {
  font-size: 1.1em;
  margin-bottom: 3em;
}
.progress-concept div {
  background-color: $secondary;
}
</style>
